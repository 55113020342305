import mermaid from 'mermaid'

import { initInternalNavigation, initMobileNav } from './navigation'
import { initSharing } from './sharing'

initMobileNav()
initInternalNavigation()
initSharing()

mermaid.initialize({ startOnLoad: true })
